body,html {
  height: 100%;
}

.bg-sidebar {
    background-color: #3945E7 !important;
}

.bg-blue {
    background-color: $pantone !important;
}

.text-blue {
    color: $pantone !important;
}

.w-20 {
    /*width: 20px;*/
}
