/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */
body {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-width: 200px;
  min-height: 100vh;
  margin-left: -13rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#menu-toggle {
  display: block;
}

#page-content-wrapper {
  min-width: 100vw;
}

#app.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #app.toggled #sidebar-wrapper {
    margin-left: -13rem;
  }

  #menu-toggle {
    display: none;
  }
}

@media (max-width: 992px) {
  .sidebar-heading {
    height: 58px
  }
}

.contenedor-menu .menu ul {
  list-style: none;
}

.contenedor-menu li a:hover {
  background: #1a95d5;
  color: #fff;
}

.contenedor-menu .menu ul {
  display: none;
}

.contenedor-menu .menu ul li a {
  /*background: #1e5f82;
  color: #fff;*/
}

.contenedor-menu .menu .activado > a {
  /*background: #1a95d5;
  color: #fff;*/
}


